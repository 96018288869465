<template>
  <div class="panel panel-primary">
    <div class="panel-body">
      <div class="row">
        <div class="col-xs-6">
          <div class="form-group form-group-sm">
            <label for="">Group Name test</label>
            <input
              class="form-control"
              :name="`facet_groups[${group.cid}][name]`"
              type="text"
              :value="group.name"
              @input="update('name', $event.currentTarget.value)"
            />
          </div>
        </div>
        <div class="col-xs-3">
          <div class="field-group">
            <label>Weight</label>
            <select
              class="form-control"
              :name="`facet_groups[${group.cid}][weight]`"
              @input="update('weight', $event.currentTarget.value)"
            >
              <option
                :value="weight"
                v-for="weight in weights"
                :key="weight"
                :selected="weight === group.weight"
                v-text="weight"
              ></option>
            </select>
          </div>
        </div>
        <div class="col-xs-3">
          <button type="button" class="btn btn-danger btn-sm" @click="remove()">
            Delete
          </button>
        </div>
      </div>

      <table class="table table-sm table-striped" v-if="facets.length > 0">
        <thead>
          <tr>
            <th>Attribute</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody
          is="grouped-facet"
          v-for="facet in facets"
          :key="facet.id"
          :facet="facet"
          :group="group"
        ></tbody>
      </table>

      <add-facet :group="group" v-if="hasUngroupedFacets"></add-facet>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AddFacet from './AddFacet';
import GroupedFacet from './GroupedFacet';
import { sortBy, range, debounce } from 'lodash';

export default {
  props: {
    group: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      weights: range(-50, 50),
    };
  },

  computed: {
    ...mapGetters(['hasUngroupedFacets', 'allFacets']),

    facets() {
      const facets = this.group.facets.reduce((acc, { weight, id }) => {
        const facet = this.allFacets.find(f => f.id === id);

        if (!facet) {
          return acc;
        }

        return [...acc, { ...facet, weight }];
      }, []);

      return sortBy(facets, [o => o.weight]);
    },
  },

  methods: {
    remove() {
      this.$store.commit('removeFacetGroup', { cid: this.group.cid });
    },

    update: debounce(function (attribute, value) {
      this.$store.commit('updateFacetGroup', {
        cid: this.group.cid,
        [attribute]: value,
      });
    }, 250),
  },

  components: {
    AddFacet,
    GroupedFacet,
  },
};
</script>
